@import '_vendors/font-awesome/fontawesome';
@import '_vendors/font-awesome/solid';
@import '_vendors/font-awesome/brands';
@import 'fancybox_partial';



html {
  scroll-margin-top: 65px;
}

body {
  background: #FFF;

  * {
    font-family: 'Open Sans', sans-serif;
  }

  .c-header {
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.15rem;
    text-align: center;
    border-bottom: 3px solid #e1cc91;
    width: fit-content;
    margin: 1rem auto;
    ;
  }

  .fancyboxcontent {
    padding: 0 !important
  }
}

.top-nav-full {
  // background: #003a5d;
  text-align: right;
  padding: 0.5rem 2.6rem 0.5rem 0;
  padding: 0;
  z-index: 99;
  position: relative;
  // border-bottom: 1px solid #CCC;

  .get-directions {
    color: #FFF;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05rem;
  }

}

.fh-nav-contains {
  margin-top: 0;
  margin-bottom: 0;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, .8);

  @media screen and (max-width: 991px) {
    max-width: 100%;
    background: rgb(225, 204, 145);
    background: linear-gradient(180deg, rgba(225, 204, 145, 1) 0%, rgba(165, 143, 98, 1) 100%);
  }

  .fh-logo img {
    height: 3rem;
  }

  #navbarSupportedContent {
    justify-content: center;
  }

  .navbar.navbar-light.bg-light {
    background-color: transparent !important;

    .navbar-toggler {
      background-color: #fff;
    }
  }

  .navbar-nav {

    li:not(:last-child) {
      a {
        &:after {
          content: " ";
          display: inline-block;
          width: 0.4rem;
          height: 0.2rem;
          background: #f9cb89;
          margin-left: 1.1rem;
          position: relative;
          margin-bottom: .4rem;
        }
      }
    }
  }

  .nav-link {
    text-transform: uppercase;
    color: #003a5d !important;
    font-weight: 600;
    letter-spacing: 0.06rem;
    font-size: 1.2rem;

    &:hover {
      text-decoration: underline;
    }

    @media screen and (max-width: 991px) {
      color: #333 !important;

      &:after {
        display: none !important;
      }
    }
  }
}

#banner {
  background-color: #003a5d;
  color: #fff;
  text-align: center;
  padding: 10px 5% !important;
  font-size: 13px;
  font-weight: 300;

  p {
    margin: 0;
    padding: 0;
    line-height: 13px;

    @media screen and (min-width: 768px) {
      line-height: 16px;
    }

    @media screen and (min-width: 1024px) {
      line-height: 18px;
    }
  }
}

.hero-wrapper {
  background: url(../img/hero_image.jpg) center center no-repeat;
  // background: url(../img/KGS_Townhomes_Exterior.jpg) center center no-repeat;
  background-size: cover;
  margin-top: 0px;
  text-align: center;
  padding-top: 2rem;
  border-bottom: 3px solid #003a5d;
  height: calc(100vh - 3rem);

  .hero-content {
    margin-bottom: 9.3rem;

    .sep-green {
      width: 47px;
      height: 4px;
      background: #f9cb89;
      margin: 1.8rem auto 2rem;
    }

    a.hero-cta {
      // background: rgb(8, 92, 96);
      // background: linear-gradient(180deg, rgba(8, 92, 96, 1) 0%, rgba(11, 36, 43, 1) 100%);
      color: #FFF;
      padding: 1rem;
      border-radius: 0.2rem;
      display: block;
      max-width: 220px;
      margin: 0rem auto;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.09rem;
      transition: all .2s linear;

      &:hover {
        text-decoration: none;
        background: rgb(225, 204, 145);
        background: linear-gradient(180deg, rgba(225, 204, 145, 1) 0%, rgba(165, 143, 98, 1) 100%);
        color: #000;
      }
    }

    .envelope {
      color: #000 !important;
      background-image: url(../img/envelope.png);
      background-size: cover;
      background-color: tan;
      background-position: center;
      border-top: 3px solid #fff;
      border-bottom: 3px solid #fff;

      &.hover {
        border: 3px solid #fff !important;
      }
    }

    #heroLogo {
      width: 100%;
      max-width: 500px;
      margin-bottom: 2rem;
      filter: drop-shadow(0px 0px 4px #fff);
    }


    p {
      font-size: 3rem;
      font-weight: 600;
      color: #FFF;
      text-transform: uppercase;
      text-shadow: 1px 3px 3px #000;
      letter-spacing: 0.12rem;
    }
  }

  @media screen and (max-width: 991px) {
    margin-top: 0;
    padding-top: 5rem;

    .hero-content p {
      font-size: 1.5rem;
    }
  }

  div#branding_wrapper {
    // background-color: rgba(255, 255, 255, 0.25);
    // backdrop-filter: blur(5px);
    padding-top: 1rem;
    margin-bottom: 2rem;
  }

}

.main-content {
  margin-top: 5rem;

  p {
    line-height: 2.5rem;
    font-weight: 600;
  }

  .section-justify {
    text-align: justify;
  }

  table.table tr th {
    font-size: 1.2rem;

    @media screen and (max-width: 991px) {
      font-size: 0.8rem;
    }
  }

  table.table tr td {
    font-weight: 600;
  }

}

.col.floor-plans img {
  max-width: 250px;
  background: #00627f;
  border-radius: 0.4rem;
  padding: 0.5rem;
  cursor: pointer;

  @media screen and (max-width: 1200px) {
    max-width: 210px;
  }

}

.modal-fp-img {
  text-align: center;

}

.modal-fp-render {
  max-width: 470px;
}

.modal-footer {
  .btn-primary {
    background: rgb(8, 92, 96);
    background: linear-gradient(180deg, rgba(8, 92, 96, 1) 0%, rgba(11, 36, 43, 1) 100%);
    color: #FFF;
    font-weight: 600;
    border: 0;
    transition: all .2s linear;

    &:hover,
    &:focus,
    &:active {
      background: rgb(225, 204, 145);
      background: linear-gradient(180deg, rgba(225, 204, 145, 1) 0%, rgba(165, 143, 98, 1) 100%);
      color: #000;
    }
  }
}

div#closeModal {
  position: absolute;
  top: 0;
  right: 0;
  background-color: pink;
  padding: .2em .4em .2em .4em;
  border-bottom-left-radius: .5em;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  transition: all .25s linear;
}

div#closeModal:hover {
  background-color: #f00;
}

.apply-online {
  text-align: center;
  margin: 3rem auto;
  max-width: 870px;

  .apply-online-btn {
    background: #f9cb89;
    color: #FFF;
    padding: 0rem;
    border-radius: 0.2rem;
    display: block;
    max-width: 290px;
    margin: 1rem auto;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.09rem;

    &:hover {
      text-decoration: none;
      background: #f9cb89;
    }
  }

  @media screen and (max-width: 991px) {
    max-width: 100%;
  }

}

.f-carousel__slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  img {
    max-height: 300px;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, .8);
    margin-bottom: 15px;
  }
}

#floorplansCarousel {
  .f-carousel__slide {
    display: flex;

    img {
      max-height: 330px;
      height: 100%;
    }
  }
}

.f-carousel__slide:not(.is-selected) {

  // display: none;
  img {
    display: none;
  }
}


//.main-content


//Footer
.footer-map {
  background: url(../img/enclave_map.gif) top center no-repeat;
  background-size: cover;
  height: 600px;
  width: 100%;
}

.footer {
  background: #003a5d;
  color: #aeadad;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  padding-top: 2rem;

  .fh-logo-footer {
    margin-bottom: 1.5rem;
    height: 4rem;
    background-color: #fff;
    padding: 5px;
  }

  .ep-logo-footer {
    width: 100%;
    max-width: 416px;
  }

  .housing-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(../img/housing-icon.png) top center no-repeat;
    margin-right: 0.7rem;
    position: relative;
    top: 0.3rem;
  }

  .address {
    text-align: center;

    a {
      color: #aeadad;
      text-decoration: underline;
      cursor: pointer;
    }
  }

}

#downArrow {
  height: 70px;
  width: 70px;
  position: absolute;
  bottom: 1rem;
  left: calc(50% - 35px);
}

#downArrow::before {
  animation: bounce 2s ease infinite;
  bottom: 2rem;
  color: #fff;
  content: '╲╱';
  font-size: 2rem;
  height: 4rem;
  left: 50%;
  letter-spacing: -1px;
  line-height: 4rem;
  margin-left: -3rem;
  opacity: 0.8;
  position: absolute;
  text-align: center;
  width: 6rem;
}

@keyframes bounce {
  50% {
    transform: translateY(-50%);
  }
}

#tours {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;

  .virtual_wrapper {
    flex-basis: 99%;

    @media screen and (min-width: 991px) {
      flex-basis: 31%;
    }
  }

  iframe {
    width: 100%;
    aspect-ratio: 4/3;
    max-width: 500px;
    margin: 0 auto;
  }
}

.f-carousel__nav {
  position: relative;

  .f-button {
    background-color: rgba(0, 0, 0, .5);
    border: 1px solid black;
    border-radius: 100%;
    color: #fff
  }
}


.fancybox__nav {
  position: relative;

  .f-button {
    background: linear-gradient(180deg, #e1cc91 0%, #a58f62 100%);
    border: 1px solid black;
    border-radius: 100%;
  }
}

.is-compact .fancybox__footer {
  text-align: center
}

.fancybox__caption a {
  background: linear-gradient(180deg, #e1cc91 0%, #a58f62 100%);
  color: #000;
  padding: .25em 1em;
}

#acceptChk {
  display: inline;
  width: fit-content;
  height: 1.5em;
  float: left;
  margin-right: .5em;
}